import { createContext, useContext, useState, useEffect } from 'react';
import { BASE_URL } from '../helper';
import api from '../api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const checkAuth = async () => {
        try {
            // Check if we have a token first
            const token = localStorage.getItem('token');
            if (!token) {
                setUser(null);
                setIsAuthenticated(false);
                setIsLoading(false);
                return;
            }

            const response = await api.get('/api/user/profile', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            
            if (response.data?.user) {
                setUser(response.data.user);
                setIsAuthenticated(true);
            } else {
                // Clear invalid token
                localStorage.removeItem('token');
                setUser(null);
                setIsAuthenticated(false);
            }
        } catch (error) {
            console.error('Auth check failed:', error);
            // Clear invalid token
            localStorage.removeItem('token');
            setUser(null);
            setIsAuthenticated(false);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        checkAuth();
    }, []);

    const login = async (email, password) => {
        setIsLoading(true);
        setError(null);
        
        try {
            const response = await api.post('/api/user/signin', { 
                email, 
                password 
            });

            const { data } = response;

            if (data.token) {
                localStorage.setItem('token', data.token);
                setUser(data.user);
                setIsAuthenticated(true);
                return { success: true };
            } else {
                throw new Error('No token received');
            }
        } catch (error) {
            const message = error.response?.data?.message || 'Login failed';
            setError(message);
            return { success: false, message };
        } finally {
            setIsLoading(false);
        }
    };

    const signup = async (userData) => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await api.post('/api/user/signup', userData);
            
            return { 
                success: true, 
                message: 'Registration successful! Please sign in.' 
            };
        } catch (error) {
            const message = error.response?.data?.message || 'Registration failed';
            setError(message);
            return { success: false, message };
        } finally {
            setIsLoading(false);
        }
    };

    const logout = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem('token');
            if (token) {
                await api.post('/api/user/logout', {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
            }
        } catch (error) {
            console.error('Logout error:', error);
        } finally {
            // Always clear local state, even if logout request fails
            localStorage.removeItem('token');
            setUser(null);
            setIsAuthenticated(false);
            setIsLoading(false);
        }

        return { success: true };
    };

    const value = {
        user,
        isAuthenticated,
        isLoading,
        error,
        login,
        logout,
        signup,
        checkAuth
    };

    return (
        <AuthContext.Provider value={value}>
            {!isLoading ? children : (
                <div className="flex items-center justify-center min-h-screen">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
                </div>
            )}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export default AuthContext;