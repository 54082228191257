// src/api.js
import axios from 'axios';
// import { BASE_URL } from './helper';
import { PiCornersOutLight } from 'react-icons/pi';
const BASE_URL = 'https://reappn.onrender.com/api'
const api = axios.create({
    baseURL: BASE_URL,
    withCredentials: true, 
    headers: {
        'Content-Type': 'application/json'
    }
});

// console.log(BASE_URL,"Base url");
// Add a request interceptor
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        console.log('Current token:', token); // Debug log
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            localStorage.removeItem('token');
            // Only redirect if not already on auth pages
            if (!window.location.pathname.match(/\/(signin|signup)$/)) {
                window.location.href = '/signin';
            }
        }
        return Promise.reject(error);
    }
);

export default api;