
// App.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from './helper';
// import './App.css';
import { FiCalendar, FiClock, FiMessageCircle, FiPhone, FiTrash2, FiPlus, FiList } from 'react-icons/fi';
import api from './api'; 
function Reminder() {
  const [reminderMsg, setReminderMsg] = useState('');
  const [remindDate, setRemindDate] = useState('');
  const [remindTime, setRemindTime] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [reminderList, setReminderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [error, setError] = useState('');
const [isDeleting, setIsDeleting] = useState(false);
  const templates = [
    { id: 'HXc43d6dfa63de62fc39278d01cd1033c4', name: 'Service Template' },
    { id: 'HX5386a60bb810cc611bab954fd8174195', name: 'Payment Template' }
  ];
  // console.log("baseurl",BASE_URL);

  const validateAndFormatPhoneNumbers = (numbers) => {
    return numbers
      .split(/[\s,]+/)
      .map(num => {
        const cleanNum = num.replace(/[^\d]/g, '');
        return cleanNum.startsWith('91') ? `+${cleanNum}` : `+91${cleanNum}`;
      })
      .filter(num => num.length === 13);
  };

  useEffect(() => {
    fetchReminders();
    const intervalId = setInterval(fetchReminders, 30000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchReminders = async () => {
    try {
      console.log('Fetching reminders...'); 
      const response = await api.get('/reminder/getAllReminders');
      setReminderList(response.data);
      setError(null);
    } catch (err) {
      if (err.response?.status === 401) {
        // Redirect to login page if unauthorized
        window.location.href = '/signin';
      } else {
        setError('Failed to fetch reminders');
        console.error('Fetch error:', err);
      }
    }
  };

  const addReminder = async () => {
    if (!remindDate || !remindTime || !phoneNumbers || !selectedTemplate) {
      setError('Please fill in all required fields and select a template');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const remindAt = new Date(`${remindDate}T${remindTime}`);
      const now = new Date();
      const thirtyDaysFromNow = new Date();
      thirtyDaysFromNow.setDate(now.getDate() + 30);

      if (remindAt < now) {
        setError('Please select a future date and time');
        setIsLoading(false);
        return;
      }

      if (remindAt > thirtyDaysFromNow) {
        setError('Reminders can only be set for up to 30 days from now');
        setIsLoading(false);
        return;
      }

      const formattedPhoneNumbers = validateAndFormatPhoneNumbers(phoneNumbers);
      
      if (formattedPhoneNumbers.length === 0) {
        setError('Please enter valid 10-digit phone numbers');
        setIsLoading(false);
        return;
      }

      const response = await api.post('/reminder/addReminder', {
        reminderMsg,
        remindAt,
        phoneNumbers: formattedPhoneNumbers,
        templateId: selectedTemplate
      });

      setReminderList(response.data);
      clearForm();
      setError(null);
    } catch (err) {
      if (err.response?.status === 401) {
        window.location.href = '/signin';
      } else {
        setError(err.response?.data?.error || 'Failed to add reminder');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const deleteReminder = async (id) => {
    if (!id || isDeleting) return;

    try {
        setIsDeleting(true);
        setError('');

        const token = localStorage.getItem('token');
        // if (!token) {
        //     setError('Authentication required');
        //     window.location.href = '/signin';
        //     return;
        // }

        const response = await api.post('/deleteReminder', 
            { id },
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );

        if (response.data.success) {
            // Update your reminder list with the new data
            setReminderList(response.data.reminders);
        } else {
            throw new Error(response.data.message || 'Failed to delete reminder');
        }
    } catch (err) {
        console.error('Delete error:', err);
        if (err.response?.status === 401) {
            localStorage.clear();
            // window.location.href = '/signin';
        } else {
            setError(err.response?.data?.message || 'Failed to delete reminder');
        }
    } finally {
        setIsDeleting(false);
    }
};

  const clearForm = () => {
    setReminderMsg('');
    setRemindDate('');
    setRemindTime('');
    setPhoneNumbers('');
    setSelectedTemplate('');
  };

  const getStatusBadgeClass = (status) => {
    const baseClasses = 'px-2 py-1 rounded-full text-xs font-semibold';
    const statusClasses = {
      pending: 'bg-yellow-100 text-yellow-800',
      queued: 'bg-blue-100 text-blue-800',
      sent: 'bg-green-100 text-green-800',
      delivered: 'bg-green-400 text-green-900',
      failed: 'bg-red-100 text-red-800'
    };
    return `${baseClasses} ${statusClasses[status] || 'bg-gray-100 text-gray-800'}`;
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 py-8">
      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center mb-10">
          <h1 className="text-4xl font-bold text-indigo-900 mb-2">
            WhatsApp Reminder App
          </h1>
          <p className="text-gray-600">Schedule and manage your reminders</p>
        </div>

        {error && (
          <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 rounded-md">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
        )}

        <div className="bg-white rounded-xl shadow-lg p-6 mb-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-6">Create New Reminder</h2>
          <div className="space-y-6">
            {/* Template Selection */}
            <div className="space-y-2">
            <label className="flex items-center text-sm font-medium text-gray-700">
  <FiList className="mr-2" />  {/* or <FiFileText className="mr-2" /> */}
  Select Template
</label>
              <select
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition"
                value={selectedTemplate}
                onChange={(e) => setSelectedTemplate(e.target.value)}
              >
                <option value="">Select a template...</option>
                {templates.map(template => (
                  <option key={template.id} value={template.id}>
                    {template.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Dates Section */}
            <div className="grid md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <label className="flex items-center text-sm font-medium text-gray-700">
                  <FiCalendar className="mr-2" />
                  Remind Date
                </label>
                <input
                  type="date"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition"
                  value={remindDate}
                  onChange={(e) => setRemindDate(e.target.value)}
                  min={new Date().toISOString().split('T')[0]}
                />
              </div>
              <div className="space-y-2">
                <label className="flex items-center text-sm font-medium text-gray-700">
                  <FiClock className="mr-2" />
                  Remind Time
                </label>
                <input
                  type="time"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition"
                  value={remindTime}
                  onChange={(e) => setRemindTime(e.target.value)}
                />
              </div>
            </div>
            {/* Phone Numbers Section */}
            <div className="space-y-2">
              <label className="flex items-center text-sm font-medium text-gray-700">
                <FiPhone className="mr-2" />
                Phone Numbers
              </label>
              <input
                type="text"
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition"
                value={phoneNumbers}
                onChange={(e) => setPhoneNumbers(e.target.value)}
                placeholder="Enter 10-digit numbers "
              />
            </div>

            <button
              onClick={addReminder}
              disabled={isLoading}
              className={`w-full flex items-center justify-center p-3 rounded-lg text-white font-medium transition-all duration-200 ${
                isLoading
                  ? 'bg-indigo-400 cursor-not-allowed'
                  : 'bg-indigo-600 hover:bg-indigo-700 transform hover:-translate-y-0.5'
              }`}
            >
              {isLoading ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Adding Reminder...
                </>
              ) : (
                <>
                  <FiPlus className="mr-2" />
                  Add Reminder
                </>
              )}
            </button>
          </div>
        </div>

        {/* Reminders List */}
        <div className="space-y-4">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Active Reminders</h2>
          {reminderList.map((reminder) => (
            <div
              key={reminder._id}
              className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow duration-200"
            >
              <div className="flex justify-between items-start">
                <div className="space-y-3 flex-grow">
                  <div className="flex items-center justify-between">
                    <h3 className="text-lg font-semibold text-gray-900">
                      {templates.find(t => t.id === reminder.templateId)?.name || 'Template Reminder'}
                    </h3>
                    <span className={getStatusBadgeClass(reminder.messageStatus)}>
                      {reminder.messageStatus}
                    </span>
                  </div>
                  <div className="grid md:grid-cols-2 gap-4 text-sm">
                    <div>
                      <p className="flex items-center text-gray-600">
                        <FiPhone className="mr-2" />
                        {reminder.phoneNumber}
                      </p>
                    </div>
                    <div>
                      <p className="flex items-center text-gray-600">
                        <FiClock className="mr-2" />
                        {new Date(reminder.remindAt).toLocaleString()}
                      </p>
                    </div>
                  </div>
                  {reminder.reminderMsg && (
                    <p className="text-gray-600 border-t pt-3 mt-3">
                      <FiMessageCircle className="inline mr-2" />
                      {reminder.reminderMsg}
                    </p>
                  )}
                  {reminder.errorMessage && (
                    <p className="text-sm text-red-600 mt-2 bg-red-50 p-2 rounded">
                      Error: {reminder.errorMessage}
                    </p>
                  )}
                </div>
                <button
                    onClick={() => {
                        if (window.confirm('Are you sure you want to delete this reminder?')) {
                            deleteReminder(reminder._id);
                        }
                    }}
                    disabled={isDeleting}
                    className={`ml-4 p-2 text-gray-400 hover:text-red-600 transition-colors duration-200 ${
                        isDeleting ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    title="Delete reminder"
                >
                    <FiTrash2 size={20} />
                </button>
              </div>
            </div>
          ))}
          {reminderList.length === 0 && (
            <div className="text-center py-12 bg-white rounded-xl shadow">
              <p className="text-gray-500">No reminders found</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Reminder;